import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Card } from "primereact/card"; // PrimeReact Card Component
import { Button } from "primereact/button"; // PrimeReact Button
import { Image } from "primereact/image"; // PrimeReact Image
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "./Helpers/Helper";

const BlogsSection = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/wp", {
          params: { per_page: 4 }, // Include per_page on client side as well.
        });
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
        // Handle error states, e.g., display an error message to the user
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []); // Call fetchPosts when component mounts or page changes

  return (
    <div className="container">
      <h1 className="my-4 text-center">Our Blogs</h1>
      <div className="row">
        {posts.length > 0 ? (
          posts.map((post) => (
            <div key={post.id} className="col-md-6 mb-4 cursor-pointer">
              <Link
                to={`/blogs/${post.slug}`}
                state={{
                  title: post.seo_title,
                  description: post.seo_description,
                }}
                className="text-decoration-none"
              >
                <Card
                  style={{ height: "100%" }}
                  title={
                    <div
                      className="text-center"
                      style={{ height: "80px" }}
                      dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                    ></div>
                  }
                >
                  <Image
                    src={
                      post.fimg_url ||
                      require("../Assets/Hero-Section-Car.webp")
                    }
                    alt="Post Thumbnail"
                    width="100%"
                    className="img-fluid d-flex justify-content-center align-items-center"
                  />
                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <Button label="Read More" className="p-button-sm start-0" />
                    <span
                      className="p-button-sm mx-5"
                      style={{ marginLeft: "auto" }}
                    >
                      <i className="pi pi-calendar mx-1" />
                      {formatDate(post.date)}
                    </span>
                  </div>
                </Card>
              </Link>
            </div>
          ))
        ) : (
          <p className="text-center mt-5">
            <i className="pi pi-spin pi-spinner fs-1" />
          </p>
        )}
      </div>

      <div className="d-flex justify-content-center mt-5">
        <Button
          label="View All"
          className=" start-0 p-3"
          style={{ fontSize: "16px" }}
          onClick={() => {
            navigate("/blogs");
          }}
        />
      </div>
    </div>
  );
};

export default BlogsSection;
