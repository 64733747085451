import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import React from "react";

const SingleLessonStep = ({ singleLessonData, setSingleLessonData }) => {
  const formatDuration = (value) => {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
      minutes ? ` ${minutes} minute${minutes > 1 ? "s" : ""}` : ""
    }`;
  };
  return (
    <div className="w-full" style={{ opacity: 1, backgroundColor: "" }}>
      <div
        className="mb-4"
        style={{
          borderRadius: "10px",
          padding: "10px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <div className="d-grid">
          <div className="col-12">
            <label>Date</label>
            <Calendar
              value={singleLessonData.date}
              onChange={(e) => {
                setSingleLessonData((prevData) => ({
                  ...prevData,
                  date: e.value,
                }));
              }}
              className="w-100"
              showTimeSelect
              dateFormat="dd/M/yy"
            />
          </div>
          <div className="col-12">
            <label>Time</label>
            <InputText
              value={singleLessonData.startTime}
              type="time"
              timeFormat="h:mm aa"
              onChange={(e) => {
                console.log(e.target.value);
                setSingleLessonData((prevData) => ({
                  ...prevData,
                  startTime: e.target.value,
                }));
              }}
              className="w-100"
            />
          </div>
          <div>
            <div className="col-12">
              <label>Duration</label>
              <Slider
                className="mt-2 mb-2 w-100"
                id="duration"
                value={singleLessonData.duration}
                onChange={(e) => {
                  console.log(e.value);
                  setSingleLessonData((prevData) => ({
                    ...prevData,
                    duration: e.value,
                  }));
                }}
                min={120}
                max={360}
                step={120}
              />
              <span>
                Selected Duration: {formatDuration(singleLessonData?.duration)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleLessonStep;
