import React, { useEffect, useRef, useState } from "react";
import { Steps } from "primereact/steps";
import PersonalInfoStep from "../Components/BookingFormSteps/PersonalInfoStep.jsx";
import BookingInfoStep from "../Components/BookingFormSteps/BookingInfoStep.jsx";
import "../styles/LessonsBooking.css";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import PackageSelectStep from "../Components/BookingFormSteps/PackageSelectStep.jsx";
import { createOrder, createSingeLessonOrder } from "../services/orders.js";
import { createUser } from "../services/users.js";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PostCode from "../Components/BookingFormSteps/PostCode.jsx";
import AbilitySelection from "../Components/BookingFormSteps/AbilitySelection.jsx";
import PaymentStep from "../Components/BookingFormSteps/PayementStep.jsx";
import LoaderModal from "../Components/LoaderModal.jsx";
import { getAreas } from "../services/areas.js";
import { OverlayPanel } from "primereact/overlaypanel";
import { getSettings } from "../services/settings.js";
import { getAllCourses } from "../services/courses.js";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import HostedPage from "./HostedPage.jsx";
import { getCoupons } from "../services/coupons.js";
import { trackCustomEvent } from "../services/facebookPixel.js";
import { Helmet } from "react-helmet-async";
import SingleLessonStep from "../Components/BookingFormSteps/SingleLessonStep.jsx";

const LessonsBooking = ({ route }) => {
  let navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const PreSelectedcourseId = queryParams.get("courseId");
  const PreSelectedcourseType = queryParams.get("courseType");
  const PreSelectedTransmission = queryParams.get("transmission");
  const PreSelectedTheoryTestOption = queryParams.get("wantTheoryTest");
  const PreSelectedPracticalTestOption = queryParams.get("wantPracticalTest");
  var screenwidth = window && window.screen ? window.screen.width : "0";
  let [activeIndex, setActiveIndex] = useState(0);
  // let toast = useRef(null);
  const formRef = useRef(null);
  const linkedMessage = location.state?.message;
  const [loading, setLoading] = useState(false);
  const [optIn, setOptIn] = useState(true);
  const [userCategory, setUserCategory] = useState(
    PreSelectedcourseType ? PreSelectedcourseType.toLowerCase() : "beginner"
  );
  const [isPostCodeValid, setIsPostCodeValid] = useState(false);

  // const bigscreen = screenwidth > 768;
  const [allAreas, setAllAreas] = useState([]);
  const [allowedPostCodes, setAllowedPostCodes] = useState([]);
  const [allSettings, setAllSettings] = useState([]);
  const [browserInfo, setBrowserInfo] = useState(null);
  const [amountpaying, setAmountpaying] = useState(0);
  const [payingOption, setPayingOption] = useState("deposit");
  const [paymentResponseCode, setPaymentResponseCode] = useState("");
  const [createdOrderId, setCreatedOrderId] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const [couponId, setCouponId] = useState(0);
  const [allCoupons, setAllCoupons] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [perHourRate, setPerHourRate] = useState(0);
  const [wantSingleLesson, setWantSingleLesson] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    postcode: params.zipCode ? params.zipCode : "",
    courseId: PreSelectedcourseId ? PreSelectedcourseId : "",
    transmission: PreSelectedTransmission ? PreSelectedTransmission : "manual",
    price: "",
    wantTheoryTest: PreSelectedTheoryTestOption
      ? JSON.parse(PreSelectedTheoryTestOption)
      : false,
    wantPracticalTest: PreSelectedPracticalTestOption
      ? JSON.parse(PreSelectedPracticalTestOption)
      : false,
    optInForContact: optIn,
    // ------------------------ payment Details
    // cardHolderName: "",
    cardNumber: "",
    cardCVV: "",
    cardExpiryMonth: "",
    cardExpiryYear: "",
    amount: "",
  });
  const [singleLessonData, setSingleLessonData] = useState({
    date: Date.now(),
    startTime: "",
    duration: 120,
  });

  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const op = useRef(null);

  const getAllareas = async () => {
    const { data } = await getAreas();
    setAllAreas(data);
    setAllowedPostCodes(data.map((area) => area.postcode));
  };
  useEffect(() => {
    getAllareas();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSettings();
        setAllSettings(data);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);
  //  __________________________________________________________
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(
    PreSelectedcourseId ? PreSelectedcourseId : null
  );
  const [selectedCourse, setSelectedCourse] = useState();

  const getCourses = async () => {
    try {
      const data = await getAllCourses();
      setCourses(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);
  useEffect(() => {
    const getAllCoupons = async () => {
      try {
        const { data } = await getCoupons();
        setAllCoupons(data);
      } catch (err) {
        console.error(err);
      }
    };
    if (selectedCourseId !== null) {
      getAllCoupons();
    }
  }, [selectedCourseId]);

  useEffect(() => {
    if (selectedCourseId !== null) {
      const course = courses.find((course) => {
        return course.id === JSON.parse(selectedCourseId); // Ensure the comparison is correct
      });
      setSelectedCourse(course);
      // console.log("Selected course ID:", selectedCourseId);
      // console.log("Courses:", courses);
      // console.log("Selected course:", course);
      handleManualChange("courseId", selectedCourseId);
    }
  }, [selectedCourseId, courses]);

  useEffect(() => {
    if (selectedCourse) {
      // console.log("Selected course:", selectedCourse);
      handleManualChange("price", selectedCourse.price);
    }
  }, [selectedCourse]);
  const stateMessage = sessionStorage.getItem("stateMessage");
  useEffect(() => {
    if (
      linkedMessage === "redirected from booking step 2" ||
      stateMessage === "redirected from booking step 2"
    ) {
      const sessionDataEmail = JSON.parse(sessionStorage.getItem("email"));
      const sessionDataPostcode = JSON.parse(
        sessionStorage.getItem("postcode")
      );
      const sessionDataName = JSON.parse(sessionStorage.getItem("name"));
      const sessionDataPhone = JSON.parse(sessionStorage.getItem("phone"));
      const sessionTransmission = JSON.parse(
        sessionStorage.getItem("transmission")
      );
      const sessionWantTheoryTest = JSON.parse(
        sessionStorage.getItem("wantTheoryTest")
      );
      const sessionWantPracticalTest = JSON.parse(
        sessionStorage.getItem("wantPracticalTest")
      );

      setInput({
        ...input,
        email: sessionDataEmail,
        postcode: sessionDataPostcode,
        name: sessionDataName,
        phone: sessionDataPhone,
        transmission: sessionTransmission || input.transmission,
        wantPracticalTest: sessionWantPracticalTest || input.wantPracticalTest,
        wantTheoryTest: sessionWantTheoryTest || input.wantTheoryTest,
      });
      // if (sessionWantTheoryTest) {
      //   // console.log(sessionWantTheoryTest);
      //   // console.log(typeof sessionWantTheoryTest);
      //   setInput({ ...input, wantTheoryTest: sessionWantTheoryTest });
      // }
      // if (sessionWantPracticalTest) {
      //   // console.log(sessionWantPracticalTest);
      //   // console.log(typeof sessionWantPracticalTest);
      //   setInput({ ...input, wantPracticalTest: sessionWantPracticalTest });
      // }
      // if (sessionTransmission) {
      //   // console.log(sessionTransmission);
      //   // console.log(typeof sessionTransmission);
      //   setInput({
      //     ...input,
      //     transmission: sessionTransmission,
      //     wantPracticalTest: sessionWantPracticalTest,
      //     wantTheoryTest: sessionWantTheoryTest,
      //   });
      // setInput({ ...input, wantTheoryTest: sessionWantTheoryTest });
      // setInput({ ...input, wantPracticalTest: sessionWantPracticalTest });
      setActiveIndex(2);
    }
    // return () => {
    //   sessionStorage.clear();
    // };
    // }
  }, [linkedMessage, stateMessage]);

  // const perHourRate = 50;
  useEffect(() => {
    if (
      wantSingleLesson &&
      singleLessonData?.date &&
      singleLessonData?.startTime &&
      singleLessonData?.duration
    ) {
      setTotalOrderAmount((singleLessonData.duration / 60) * perHourRate);
    }
  }, [wantSingleLesson, singleLessonData, perHourRate]);
  const saveToSessionHandler = () => {
    sessionStorage.setItem("postcode", JSON.stringify(input.postcode));
    sessionStorage.setItem("email", JSON.stringify(input.email));
    sessionStorage.setItem("name", JSON.stringify(input.name));
    sessionStorage.setItem("phone", JSON.stringify(input.phone));
    sessionStorage.setItem("stateMessage", "redirected from booking step 2");
  };
  const handleCoupon = () => {
    const matchedCoupon = allCoupons.find(
      (coupon) => coupon.code === couponCode
    );
    // // console.log(matchedCoupon);
    if (!matchedCoupon) {
      toast.error("invalid Coupon Code");
      return;
    }
    if (
      (matchedCoupon && matchedCoupon.is_active === true) ||
      (matchedCoupon?.individual_use_only &&
        matchedCoupon?.allowed_emails === input.email &&
        (matchedCoupon?.useage_count === 0 ||
          matchedCoupon?.useage_count == null))
    ) {
      setCouponId(matchedCoupon.id);
      if (matchedCoupon?.type === "percentage") {
        setDiscount((totalOrderAmount * matchedCoupon.value) / 100);
        toast.success("Coupon Applied Successfully");
      }
      if (matchedCoupon?.type === "fixed") {
        setDiscount(matchedCoupon.value);
        // // console.log(discount);
        toast.success("Coupon Applied Successfully");
      }
    } else {
      toast.error("Invalid Coupon");
      return;
    }
  };
  const handleCouponRemove = () => {
    setCouponId(null);
    setDiscount(0);
  };
  const handleDepositClick = () => {
    setPayingOption("deposit");
  };

  const handleFullPaymentClick = () => {};
  // ____________________________________________________________
  const phone = allSettings.find((setting) => setting.name === "phone")?.value;
  const postCodeCheck = allSettings.find(
    (setting) => setting.name === "post_code_checking"
  )?.value;
  const theoryTestPrice = input.wantTheoryTest
    ? parseFloat(
        allSettings.find((setting) => setting.name === "theory_test_price")
          ?.value
      )
    : 0;
  const practicalTestPrice = input.wantPracticalTest
    ? parseFloat(
        allSettings.find((setting) => setting.name === "practical_test_price")
          ?.value
      )
    : 0;
  const basicDepositAmount = parseFloat(
    allSettings.find((setting) => setting.name === "basic_deposit")?.value
  );

  let customerId;
  // const totalOrderAmount =
  //   selectedCourse?.price + practicalTestPrice + theoryTestPrice;

  useEffect(() => {
    const coursePrice = selectedCourse?.price || 0;
    const PracticalTestPrice = input.wantPracticalTest ? practicalTestPrice : 0;
    const TheoryTestPrice = input.wantTheoryTest ? theoryTestPrice : 0;
    const discountAmount = discount || 0;

    const calculatedTotal =
      coursePrice + PracticalTestPrice + TheoryTestPrice - discountAmount;

    setTotalOrderAmount(calculatedTotal);
  }, [selectedCourse, input.wantPracticalTest, input.wantTheoryTest, discount]);
  const necessaryDeposit =
    basicDepositAmount +
    selectedCourse?.course_deposit +
    theoryTestPrice +
    practicalTestPrice;
  const totalAmountExcludingDeposit = totalOrderAmount - necessaryDeposit;
  const currentlyPayingAmount =
    payingOption === "deposit" ? necessaryDeposit : totalOrderAmount;
  const checkpostCodeMatch = () => {
    const threezipCodeChr = input.postcode.slice(0, 3);
    const isSpace = input.postcode.charAt(2) === " ";
    const checkForInclusion = isSpace
      ? threezipCodeChr.slice(0, 2)
      : threezipCodeChr.slice(0, 3);
    if (
      allowedPostCodes.includes(checkForInclusion)
      // && input.zipcode.length >= 2
    ) {
      setIsPostCodeValid(true);
      return true;
    } else {
      setIsPostCodeValid(false);
      return false;
    }
  };

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const handleManualChange = (name, value) => {
    setInput({ ...input, [name]: value });
  };
  const paymentDetails = {
    cardNumber: input.cardNumber,
    cardCVV: input.cardCVV,
    cardExpiryMonth: input.cardExpiryMonth,
    cardExpiryYear: input.cardExpiryYear,
    amount: input.amount,
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update state based on screen size
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e) => {
    // console.log("submission");
    e.preventDefault();
    await createUser({ user: input })
      .then((res) => {
        customerId = res.user.id;
      })
      .catch((err) => {
        // console.log(err);
      });
    if (wantSingleLesson) {
      await createSingeLessonOrder({
        order: input,
        customer_id: customerId,
        orderTotal: totalOrderAmount,
        singleLessonData: singleLessonData,
      })
        .then((res) => {
          setLoading(false);
          handleBookingCompletion();
          setCreatedOrderId(res.order.id);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Please Fill All the fields correctly");
        });
    } else {
      await createOrder({
        order: input,
        // amount_paid: paymentResponseCode === "0" ? currentlyPayingAmount : 0,
        // amountPaying: currentlyPayingAmount,
        orderTotal: totalOrderAmount,
        customer_id: customerId,
        coupon_id: couponId,
        // paymentSuccessful: paymentSucceeded,
        // paymentDetails: paymentDetails,
        browserInfo: browserInfo,
        discountApplied: discount >= 1 ? true : false,
        // discountCoupon: discount >= 1 ? couponCode : "",
      })
        .then((res) => {
          setLoading(false);
          handleBookingCompletion();
          // toast.success("Your order is Placed Successfully");
          // console.log(res);
          // console.log(res.order.id);
          setCreatedOrderId(res.order.id);
          // console.log(createdOrderId);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Please Fill All the fields correctly");
          // console.log(err);
        });
    }
    // else {
    //   return;
    // }
  };

  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex === itemIndex;
    const backgroundColor = isActiveItem
      ? "var(--primary-color)"
      : "var(--surface-b)";
    const textColor = isActiveItem
      ? "var(--surface-b)"
      : "var(--text-color-secondary)";
    return (
      // isPostCodeValid &&
      <span
        className="inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer"
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          marginTop: "-25px",
        }}
        onClick={
          activeIndex !== 3 &&
          (isPostCodeValid || postCodeCheck !== "enable") &&
          input.email &&
          input.name &&
          input.phone
            ? () => setActiveIndex(itemIndex)
            : (e) => {
                input.postcode.length < 1 ||
                input.email.length < 1 ||
                input.name.length < 1 ||
                input.phone < 1
                  ? toast.error("Please Enter All Fields")
                  : op.current.toggle(e);
              }
        }
      >
        <i className={`${item.icon} text-md`} />
        <OverlayPanel ref={op} closeOnEscape dismissable={true}>
          {activeIndex === 3 ? (
            <p>You Cannot Go Back From This Stage</p>
          ) : (
            <p>
              At the moment all our instructor are booked
              <Link
                onClick={() => (window.location = `tel:${phone}`)}
                style={{ textDecoration: "none" }}
              >
                please call{" "}
              </Link>{" "}
              <br /> or leave your your details and we will contact you with
              <br />
              first availability thanks
            </p>
          )}
        </OverlayPanel>
      </span>
    );
  };

  const items = [
    {
      icon: "pi pi-user",
      template: (item) => itemRenderer(item, 0),
    },
    {
      icon: "pi pi-car",
      template: (item) => itemRenderer(item, 1),
    },
    {
      icon: "pi pi-book",
      template: (item) => itemRenderer(item, 2),
    },
    {
      icon: "pi pi-wallet",
      template: (item) => itemRenderer(item, 3),
    },
  ];
  const handleIconClick = (e) => {
    if (isPostCodeValid) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    } else {
      op.current.toggle(e);
    }
  };
  const handleBookingCompletion = () => {
    // console.log("tracking booking completion");
    trackCustomEvent("BookingCompleted", {
      content_name: "Booking Completed",
      content_category: "Course Booking",
      booking_value: totalOrderAmount, // Replace with dynamic value if available
      currency: "GBP",
    });
    // console.log("Tracked booking completion");
  };
  const handleButtonClick = async (e) => {
    // console.log(postCodeCheck);
    // console.log(typeof postCodeCheck);
    // console.log(postCodeCheck !== "enable");

    e.preventDefault();
    setWantSingleLesson(false);
    if (formRef.current.checkValidity()) {
      const threepostcodeChr = input.postcode.slice(0, 3);
      const isSpace = input.postcode.charAt(2) === " ";
      const checkForInclusion = isSpace
        ? threepostcodeChr.slice(0, 2)
        : threepostcodeChr.slice(0, 3);
      const toUpperCaseArray = (array) =>
        array.map((item) => item?.toUpperCase());
      if (
        toUpperCaseArray(allowedPostCodes).includes(
          checkForInclusion?.toUpperCase()
        )
      ) {
        setIsPostCodeValid(true);
        setActiveIndex((prevIndex) => prevIndex + 1);
      } else if (postCodeCheck !== "enable") {
        setIsPostCodeValid(true);
        setActiveIndex((prevIndex) => prevIndex + 1);
      } else {
        // // console.log("else condition");

        setIsPostCodeValid(false);
        op.current.toggle(e);
      }
    } else {
      formRef.current.reportValidity();
    }

    if (optIn) {
      await createUser({ user: input })
        .then((res) => {
          // console.log(res);
          // console.log("user api hit");
          // // console.log("userid", res.user[0].id);
          customerId = res.user.id;
          // console.log(customerId);
        })
        .catch((err) => {
          // console.log(err);
          // // console.log("user api not hit");
        });
    }
  };
  const handleBookSingleLessonButtonClick = async (e) => {
    e.preventDefault();
    setWantSingleLesson(true);
    for (const key of queryParams.keys()) {
      queryParams.delete(key);
    }
    setInput((prevInput) => ({
      ...prevInput,
      courseId: "",
    }));
    const settingPerHourRate = parseFloat(
      allSettings.find((setting) => setting.name === "price_per_hour")?.value
    );

    if (input.postcode) {
      const matchedPostcodeArea = allAreas.find(
        (area) => area.postcode === input.postcode
      );
      if (matchedPostcodeArea.price_per_hour) {
        setPerHourRate(matchedPostcodeArea.price_per_hour);
      } else {
        setPerHourRate(settingPerHourRate);
      }
    }
    if (formRef.current.checkValidity()) {
      const threepostcodeChr = input.postcode.slice(0, 3);
      const isSpace = input.postcode.charAt(2) === " ";
      const checkForInclusion = isSpace
        ? threepostcodeChr.slice(0, 2)
        : threepostcodeChr.slice(0, 3);
      const toUpperCaseArray = (array) =>
        array.map((item) => item?.toUpperCase());
      if (
        toUpperCaseArray(allowedPostCodes).includes(
          checkForInclusion?.toUpperCase()
        )
      ) {
        setIsPostCodeValid(true);
        setActiveIndex(2);
      } else if (postCodeCheck !== "enable") {
        setIsPostCodeValid(true);
        setActiveIndex(2);
      } else {
        setIsPostCodeValid(false);
        op.current.toggle(e);
      }
    } else {
      formRef.current.reportValidity();
    }
    if (optIn) {
      await createUser({ user: input })
        .then((res) => {
          customerId = res.user.id;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const showMissingFieldsToast = (message) => {
    // toastRef.current.clear();
    const missingFields = [];
    if (!input.postcode) missingFields.push("Postcode");
    if (!input.email) missingFields.push("Email");
    if (!input.name) missingFields.push("Name");
    if (!input.phone) missingFields.push("Phone");
    if (message) {
      toast.error(message);
    }
    if (missingFields.length > 0) {
      toast.error(
        `Please fill in the following fields: ${missingFields.join(", ")}`
      );
    }
  };
  const handleSwitchChange = (field, value) => {
    setInput((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return !loading ? (
    <div
      className="card align-content-center justify-content-center mt-5 outer-stepper-div col-md-6"
      style={{}}
    >
      <Helmet>
        <link rel="canonical" href="https://pass-swiftly.com/booking" />
        {queryParams.toString() && <meta name="robots" content="noindex" />}
        <title>Book Your Driving Lesson Online | Pass Swiftly UK </title>
        <meta
          name="description"
          content="Easily book driving lessons online with Pass Swiftly. Choose from flexible schedules and expert instructors to suit your driving needs. Start your journey today!"
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="stepper-div" style={{}}>
            <Steps
              model={items}
              activeIndex={activeIndex}
              readOnly={false}
              className="m-2 pt-4"
            />
          </div>
          {activeIndex === 2 &&
            input.courseId &&
            window?.screen?.width > 768 && (
              <div className="order-summary-card order-summary-container col-md-3">
                <div className="d-flex align-items-center mb-4">
                  <p className="h5 font-semibold">Order Summary</p>
                </div>
                <div className="order-summary-section">
                  <div className="d-flex justify-content-between">
                    <span>{selectedCourse?.hours} Hours Course</span>
                    <span className="">£{selectedCourse?.price}</span>
                  </div>
                </div>
                <div className="order-summary-section">
                  {input.wantPracticalTest && (
                    <div className="d-flex justify-content-between mt-2">
                      <span>Practical Test</span>
                      <span className="">£{practicalTestPrice}</span>
                    </div>
                  )}
                  {input.wantTheoryTest && (
                    <div className="d-flex justify-content-between mt-2">
                      <span>Theory Test</span>
                      <span className="">£{theoryTestPrice}</span>
                    </div>
                  )}
                </div>
                <div className="order-summary-section mt-4">
                  <div className="d-flex justify-content-between fs-3">
                    <span>Deposit </span>
                    <span className="">£{necessaryDeposit}</span>
                  </div>
                </div>
                <div className="order-summary-section mt-4">
                  <div className="d-flex justify-content-between fs-4">
                    <span>Remaining</span>
                    <span className="font-weight-bold">
                      £{totalAmountExcludingDeposit}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between fs-5">
                    <span>Total Amount</span>
                    <span className="font-weight-bold">
                      £{totalOrderAmount}
                    </span>
                  </div>
                  {discount > 0 && (
                    <div className="d-flex justify-content-between fs-6">
                      <span>You Have Saved</span>
                      <span className="font-weight-bold">£{discount}</span>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <RadioButton
                      type="radio"
                      inputId="payDeposit"
                      name="payment"
                      checked={payingOption === "deposit"}
                      onChange={(e) => {
                        setPayingOption("deposit");
                        // console.log(input.amount);
                      }}
                    />
                    {"  "}
                    <label htmlFor="payDeposit" style={{ fontWeight: "700" }}>
                      {" "}
                      Pay Deposit
                    </label>
                  </div>

                  <strong>OR</strong>
                  <div>
                    <RadioButton
                      type="radio"
                      inputId="payFull"
                      name="payment"
                      checked={payingOption === "full"}
                      onChange={(e) => {
                        setPayingOption("full");
                        // console.log(input.amount);
                      }}
                    />
                    {"  "}
                    <label htmlFor="payFull" style={{ fontWeight: "700" }}>
                      {" "}
                      Pay Total
                    </label>
                  </div>
                </div>
              </div>
            )}
          {wantSingleLesson &&
            activeIndex === 2 &&
            singleLessonData.date &&
            singleLessonData.startTime &&
            singleLessonData.duration &&
            window?.screen?.width > 768 && (
              <div className=" order-summary-card order-summary-container col-md-3    ">
                <p className="h5 font-semibold color-quaternary text-center ">
                  Order Summary
                </p>
                <div className="d-flex align-items-center mb-4"></div>
                {/* <div className="order-summary-section">Hour Rate</div>
                <div className="order-summary-section mt-4"></div> */}
                <div className="order-summary-section mt-4">
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between fs-5">
                    <span>Total Hours Booked</span>
                    <span className="font-weight-bold">
                      {singleLessonData?.duration / 60}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between fs-5">
                    <span>Lesson Price Per Hour</span>
                    <span className="font-weight-bold">£{perHourRate}</span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between fs-5">
                    <span>Total Amount</span>
                    <span className="font-weight-bold">
                      £{(singleLessonData?.duration / 60) * perHourRate}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>
            )}
        </div>
        <div
          className="align-items-center justify-content-center components-container"
          style={{
            width: "65%",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          {activeIndex === 0 && (
            <PostCode
              input={input}
              handleChange={handleChange}
              optIn={optIn}
              setOptIn={setOptIn}
              ref={formRef}
            />
          )}
          {activeIndex === 1 && (
            <AbilitySelection
              saveToSessionHandler={saveToSessionHandler}
              userCategory={userCategory}
              setUserCategory={setUserCategory}
            />
          )}

          {activeIndex === 2 && !wantSingleLesson && (
            <PackageSelectStep
              discount={discount}
              handleCouponRemove={handleCouponRemove}
              handleCoupon={handleCoupon}
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              PreSelectedcourseId={PreSelectedcourseId}
              userCategory={userCategory}
              input={input}
              handleSwitchChange={handleSwitchChange}
              handleChange={handleChange}
              handleManualChange={handleManualChange}
              courses={courses}
              selectedCourseId={selectedCourseId}
              selectedCourse={selectedCourse}
              setSelectedCourseId={setSelectedCourseId}
            />
          )}
          {activeIndex === 2 && wantSingleLesson && (
            <SingleLessonStep
              singleLessonData={singleLessonData}
              setSingleLessonData={setSingleLessonData}
            />
          )}
          {activeIndex === 3 &&
            createdOrderId &&
            (wantSingleLesson ? totalOrderAmount : currentlyPayingAmount) && (
              <HostedPage
                orderRef={createdOrderId}
                amount={
                  wantSingleLesson ? totalOrderAmount : currentlyPayingAmount
                }
              />
            )}
        </div>
        <div className="col d-md-none d-flex flex-wrap justify-content-center align-items-center">
          {isMobile && (
            <div
              className="align-items-center  d-flex d-md-none justify-content-center components-container-mobile"
              style={{
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
                width: "100%",
              }}
            >
              {activeIndex === 0 && (
                <PostCode
                  input={input}
                  handleChange={handleChange}
                  optIn={optIn}
                  setOptIn={setOptIn}
                  // handleManualChange={handleManualChange}
                />
              )}
              {activeIndex === 1 && (
                <AbilitySelection
                  saveToSessionHandler={saveToSessionHandler}
                  userCategory={userCategory}
                  setUserCategory={setUserCategory}
                />
              )}
              {activeIndex === 2 && !wantSingleLesson && (
                <PackageSelectStep
                  discount={discount}
                  handleCouponRemove={handleCouponRemove}
                  handleCoupon={handleCoupon}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  PreSelectedcourseId={PreSelectedcourseId}
                  userCategory={userCategory}
                  input={input}
                  handleSwitchChange={handleSwitchChange}
                  handleChange={handleChange}
                  handleManualChange={handleManualChange}
                  courses={courses}
                  selectedCourseId={selectedCourseId}
                  selectedCourse={selectedCourse}
                  setSelectedCourseId={setSelectedCourseId}
                />
              )}
              {activeIndex === 2 && wantSingleLesson && (
                <SingleLessonStep
                  singleLessonData={singleLessonData}
                  setSingleLessonData={setSingleLessonData}
                />
              )}
              {activeIndex === 3 &&
                createdOrderId &&
                (wantSingleLesson
                  ? totalOrderAmount
                  : currentlyPayingAmount) && (
                  <HostedPage
                    orderRef={createdOrderId}
                    amount={
                      wantSingleLesson
                        ? totalOrderAmount
                        : currentlyPayingAmount
                    }
                  />
                )}
            </div>
          )}
          {activeIndex === 2 && input.courseId && !wantSingleLesson && (
            <div className="order-summary-card order-summary-container col-md-3">
              <div className="d-flex align-items-center mb-4">
                <p className="h5 font-semibold">Order Summary</p>
              </div>
              <div className="order-summary-section">
                <div className="d-flex justify-content-between">
                  <span>{selectedCourse?.hours} Hours Course</span>
                  <span className="">£{selectedCourse?.price}</span>
                </div>
              </div>
              <div className="order-summary-section">
                {input.wantPracticalTest && (
                  <div className="d-flex justify-content-between mt-2">
                    <span>Practical Test</span>
                    <span className="">£{practicalTestPrice}</span>
                  </div>
                )}
                {input.wantTheoryTest && (
                  <div className="d-flex justify-content-between mt-2">
                    <span>Theory Test</span>
                    <span className="">£{theoryTestPrice}</span>
                  </div>
                )}
              </div>
              <div className="order-summary-section mt-4">
                <div className="d-flex justify-content-between fs-3">
                  <span>Deposit </span>
                  <span className="">£{necessaryDeposit}</span>
                </div>
              </div>
              <div className="order-summary-section mt-4">
                <div className="d-flex justify-content-between fs-4">
                  <span>Remaining</span>
                  <span className="font-weight-bold">
                    £{totalAmountExcludingDeposit}
                  </span>
                </div>
                <hr />
                {/* {discount > 0 && (
                  <div className="d-flex justify-content-between fs-5">
                    <span>Discount</span>
                    <span className="font-weight-bold">£{discount}</span>
                  </div>
                )} */}
                <div className="d-flex justify-content-between fs-5">
                  <span>Total Amount</span>
                  <span className="font-weight-bold">£{totalOrderAmount}</span>
                </div>
                {discount > 0 && (
                  <div className="d-flex justify-content-between fs-6">
                    <span>You Have Saved</span>
                    <span className="font-weight-bold">£{discount}</span>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div>
                  <RadioButton
                    type="radio"
                    inputId="payDeposit"
                    name="payment"
                    checked={payingOption === "deposit"}
                    onChange={(e) => {
                      setPayingOption("deposit");
                      // console.log(input.amount);
                    }}
                  />
                  {"  "}
                  <label htmlFor="payDeposit" style={{ fontWeight: "700" }}>
                    {" "}
                    Pay Deposit
                  </label>
                </div>

                <strong>OR</strong>
                <div>
                  <RadioButton
                    type="radio"
                    inputId="payFull"
                    name="payment"
                    checked={payingOption === "full"}
                    onChange={(e) => {
                      setPayingOption("full");
                      // console.log(input.amount);
                    }}
                  />
                  {"  "}
                  <label htmlFor="payFull" style={{ fontWeight: "700" }}>
                    {" "}
                    Pay Total
                  </label>
                </div>
              </div>
            </div>
          )}
          {wantSingleLesson &&
            activeIndex === 2 &&
            singleLessonData.date &&
            singleLessonData.startTime &&
            singleLessonData.duration && (
              <div className=" order-summary-card order-summary-container col-md-3    ">
                <p className="h5 font-semibold color-quaternary text-center ">
                  Order Summary
                </p>
                <div className="d-flex align-items-center mb-4"></div>
                {/* <div className="order-summary-section">Hour Rate</div>
                <div className="order-summary-section mt-4"></div> */}
                <div className="order-summary-section mt-4">
                  {/* <hr /> */}
                  <div className="d-flex justify-content-between fs-5">
                    <span>Total Hours Booked</span>
                    <span className="font-weight-bold">
                      {singleLessonData?.duration / 60}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between fs-5">
                    <span>Lesson Price Per Hour</span>
                    <span className="font-weight-bold">£{perHourRate}</span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between fs-5">
                    <span>Total Amount</span>
                    <span className="font-weight-bold">
                      £{(singleLessonData?.duration / 60) * perHourRate}
                    </span>
                  </div>
                  <hr />
                </div>
              </div>
            )}
        </div>
        <div className="flex justify-content-center">
          {activeIndex !== 0 && activeIndex !== 3 && (
            <Button
              style={{ width: "25%" }}
              className="mx-2 align-items-center justify-content-center btn-danger "
              onClick={() => {
                setActiveIndex(
                  activeIndex === 2 && wantSingleLesson
                    ? 0
                    : (prevIndex) => prevIndex - 1
                );
              }}
              type="button"
            >
              Previous
            </Button>
          )}
          {activeIndex !== 3 && activeIndex !== 0 && activeIndex !== 2 && (
            <Button
              style={{ width: "25%" }}
              className="mx-2 align-items-center justify-content-center"
              onClick={() => {
                setActiveIndex((prevIndex) => prevIndex + 1);
              }}
              type="button"
            >
              Next
            </Button>
          )}
          {activeIndex === 0 && (
            <>
              <Button
                // style={{ width: "25%" }}
                className="mx-2 align-items-center justify-content-center bg-dark text-white border-0 w-12 md:w-6 lg:w-3"
                onClick={handleBookSingleLessonButtonClick}
                type="button"
              >
                Get Started With Trial Lesson
                {/* Book Single Lesson */}
              </Button>
              <Button
                // style={{ width: "25%" }}
                className="mx-2 align-items-center justify-content-center w-12 md:w-6 lg:w-3"
                onClick={handleButtonClick}
                type="button"
              >
                Next (Book Course)
              </Button>
              <OverlayPanel ref={op} closeOnEscape dismissable={true}>
                <p>
                  At the moment all our instructor are booked{" "}
                  <Link
                    onClick={() => (window.location = `tel:${phone}`)}
                    style={{ textDecoration: "none" }}
                  >
                    please call{" "}
                  </Link>{" "}
                  <br /> or leave your your details and we will contact you with
                  <br />
                  first availability thanks
                </p>
              </OverlayPanel>
            </>
          )}
          {activeIndex === 2 && (
            <Button
              style={{ width: "25%" }}
              className="mx-2 align-items-center justify-content-center"
              disabled={
                wantSingleLesson
                  ? !singleLessonData.date ||
                    !singleLessonData.startTime ||
                    !singleLessonData.duration
                  : !selectedCourseId
              }
              onClick={(e) => {
                // handleBookingCompletion();
                setActiveIndex((prevIndex) => prevIndex + 1);
                handleSubmit(e);
              }}
            >
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  ) : (
    <LoaderModal modalShow={loading} />
  );
};

export default LessonsBooking;
