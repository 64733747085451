import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Card } from "primereact/card"; // PrimeReact Card Component
import { Button } from "primereact/button"; // PrimeReact Button
import { Image } from "primereact/image"; // PrimeReact Image
import { formatDate } from "../Components/Helpers/Helper";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // More explicit "has more" state
  const loaderRef = useRef(null);

  const fetchPosts = useCallback(async () => {
    // useCallback for memoization
    if (loading || !hasMore) return; // Prevent concurrent fetches or fetching when no more data

    setLoading(true);

    try {
      const response = await axios.get("/api/wp", {
        params: { page, per_page: 10 }, // Include per_page on client side as well.
      });

      if (response.data.length === 0) {
        setHasMore(false); // No more posts from WP API
      } else {
        setPosts((prevPosts) => {
          const newPosts = response.data.filter(
            (post) => !prevPosts.some((p) => p.id === post.id)
          );
          return [...prevPosts, ...newPosts];
        });
        // setPosts((prevPosts) => [...prevPosts, ...response.data]);
        if (parseInt(response.headers["x-wp-totalpages"], 10) <= page) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      // Handle error states, e.g., display an error message to the user
    } finally {
      setLoading(false);
    }
  }, [page, loading, hasMore]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]); // Call fetchPosts when component mounts or page changes

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 } // Trigger when even 1 pixel is visible
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, []); // Empty dependency array - runs only on mount/unmount

  return (
    <div className="container">
      <Helmet>
        <title>Our Blog | Driving Tips & Licence Guides | Pass Swiftly</title>
        <meta
          name="description"
          content="Read our blog for expert driving tips, licence advice, and the latest updates on UK driving regulations. Stay informed and improve your driving skills with Pass Swiftly!"
        />
      </Helmet>
      <h1 className="my-4 text-center">Blog Posts</h1>
      <div className="row">
        {posts.length > 0 ? (
          posts.map((post) => (
            <div key={post.id} className="col-md-6 mb-4 cursor-pointer">
              <Link
                to={`/blogs/${post.slug}`}
                state={{
                  title: post.seo_title,
                  description: post.seo_description,
                }}
                className="text-decoration-none"
              >
                <Card
                  style={{ height: "100%" }}
                  title={
                    <div
                      className="text-center"
                      style={{ height: "80px" }}
                      dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                    ></div>
                  }
                >
                  <Image
                    src={
                      post.fimg_url ||
                      require("../Assets/Hero-Section-Car.webp")
                    }
                    alt="Post Thumbnail"
                    width="100%"
                    className="img-fluid d-flex justify-content-center align-items-center"
                  />
                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <Button label="Read More" className="p-button-sm start-0" />
                    <span
                      className="p-button-sm mx-5"
                      style={{ marginLeft: "auto" }}
                    >
                      <i className="pi pi-calendar mx-1" />
                      {formatDate(post.date)}
                    </span>
                  </div>
                </Card>
              </Link>
            </div>
          ))
        ) : (
          <p className="text-center mt-5">
            <i className="pi pi-spin pi-spinner fs-1" />
          </p>
        )}
      </div>

      {/* Loader element */}
      <div ref={loaderRef} className="text-center mt-4">
        {posts.length > 0 && loading && (
          <i className="pi pi-spin pi-spinner fs-1" />
        )}
      </div>
    </div>
  );
};

export default Blog;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Card } from "primereact/card"; // PrimeReact Card Component
// import { Button } from "primereact/button"; // PrimeReact Button
// import { Image } from "primereact/image"; // PrimeReact Image
// import { formatDate } from "../Components/Helpers/Helper";
// import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet-async";

// const Blog = () => {
//   // useEffect(() => {
//   //   let response;
//   //   response = axios.get(
//   //     "https://www.pass-swiftly.com/content/wp-json/wp/v2/posts"
//   //   );
//   //   console.log(response);
//   // }, []);
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const fetchPosts = async () => {
//       try {
//         let response;
//         // response = await axios.get(
//         //   "https://www.pass-swiftly.com/content/wp-json/wp/v2/posts"
//         // );
//         response = await axios.get("/api/wp");
//         console.log(response);
//         setPosts(response.data);
//       } catch (error) {
//         console.error("Error fetching the posts:", error);
//       }
//     };

//     fetchPosts();
//   }, []);
//   return (
//     <div className="container">
//       <Helmet>
//         <title>Our Blog | Driving Tips & Licence Guides | Pass Swiftly</title>
//         {/* <title>Driving Tips & Licence Guides | Pass Swiftly Blog</title> */}
//         <meta
//           name="description"
//           content="Read our blog for expert driving tips, licence advice, and the latest updates on UK driving regulations. Stay informed and improve your driving skills with Pass Swiftly!"
//         />
//       </Helmet>
//       <h1 className="my-4 text-center">Blog Posts</h1>
//       <div className="row">
//         {posts.length > 0 ? (
//           posts.map((post) => (
//             <div
//               key={post.id}
//               className="col-md-6 mb-4 cursor-pointer"
//               // onClick={() => (window.location.href = `/blogs/${post.slug}`)}
//             >
//               <Link
//                 to={`/blogs/${post.slug}`}
//                 state={{
//                   title: post.seo_title,
//                   description: post.seo_description,
//                 }}
//                 className="text-decoration-none"
//               >
//                 <Card
//                   style={{ height: "100%" }}
//                   title={
//                     <div
//                       className="text-center"
//                       style={{ height: "80px" }}
//                       dangerouslySetInnerHTML={{ __html: post.title.rendered }}
//                     ></div>
//                   }
//                 >
//                   <Image
//                     src={
//                       post.fimg_url ||
//                       require("../Assets/Hero-Section-Car.webp")
//                     }
//                     // src={
//                     //   post.featured_media_url ||
//                     //   "https://primefaces.org/cdn/primereact/images/galleria/galleria12.jpg" ||
//                     //   "https://via.placeholder.com/300"
//                     // } // Placeholder or actual image URL
//                     alt="Post Thumbnail"
//                     width="100%"
//                     className="img-fluid d-flex justify-content-center align-items-center" // Bootstrap class for responsive image
//                   />
//                   <div className="mt-3 d-flex align-items-center justify-content-between">
//                     <Button
//                       label="Read More"
//                       className="p-button-sm start-0"
//                       // onClick={() =>
//                       //   (window.location.href = `/post/${post.slug}`)
//                       // }
//                     />
//                     <span
//                       className="p-button-sm mx-5"
//                       style={{ marginLeft: "auto" }}
//                     >
//                       <i className="pi pi-calendar mx-1" />
//                       {formatDate(post.date)}
//                     </span>
//                   </div>
//                 </Card>
//               </Link>
//             </div>
//           ))
//         ) : (
//           <p className="text-center mt-5">
//             {" "}
//             <i className="pi pi-spin pi-spinner fs-1" />
//           </p>
//           /* <p>Loading posts...</p> */
//         )}
//       </div>
//     </div>
//   );
// };

// export default Blog;
