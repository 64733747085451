import React, { useState } from "react";
import { Button } from "primereact/button";
import { createSubscription } from "../services/subscriptions";
import { toast } from "react-toastify";

const SubscriptionSection = () => {
  const [subscriptionData, setSubscriptionData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleSubscriptionPress = async () => {
    try {
      const response = await createSubscription(subscriptionData);
      console.log(response?.message);

      if (response.success) {
        toast.success(response?.message);
        setSubscriptionData({
          name: "",
          email: "",
          phone: "",
        });
      } else if (response.alreadySubscribed) {
        toast.warning(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again");
      console.error(error);
    }
  };
  return (
    <section className=" text-center py-6 px-4 bg-yellow-50 my-3 ">
      <div className="p-text-center ">
        <h2 className="faq-text-center mb-4">Stay Connected & Save Big!</h2>
        <p className="text-lg mb-4 text-600">
          Subscribe to our newsletter and unlock exclusive deals, updates, and
          your very own promo code. Don’t miss out!
        </p>
      </div>

      <div className="flex flex-column md:flex-row justify-content-center align-items-center gap-3">
        <input
          type="email"
          value={subscriptionData.email}
          onChange={(e) =>
            setSubscriptionData((prevData) => ({
              ...prevData,
              email: e.target.value,
            }))
          }
          placeholder="Enter your email"
          className="p-inputtext p-component w-full md:w-30rem border-300 p-3 border-round"
        />
        <Button
          label="Subscribe & Get Your Promo Code"
          className="p-button-primary p-button-lg w-full md:w-auto"
          onClick={handleSubscriptionPress}
        />
      </div>
    </section>
  );
};

export default SubscriptionSection;
