import React, { useContext, useEffect, useState } from "react";
import "../styles/Home.css";
import HeroSection from "../Components/HeroSection";
import AfterHeroSection from "../Components/AfterHeroSection";
import BenifitsSection from "../Components/BenifitsSection";
import FindCourseSection from "../Components/FindCourseSection";
import Reviews from "../Components/Reviews";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { UserContext } from "../contexts/UserContext";
import { Outlet, useLocation } from "react-router-dom";
import MoneyBackGuarantee from "../Components/MoneyBackGuarantee";
import DiscountPromosSection from "../Components/DiscountPromosSection";
import VideosSection from "../Components/VideosSection";
import CourseRecommenderSection from "../Components/CourseRecommenderSection";
import BussinessReviewsSection from "../Components/BussinessReviewsSection";
import FAQSection from "../Components/FaqSection";
import { Helmet } from "react-helmet-async";
import BlogsSection from "../Components/BlogsSection";
import SubscriptionSection from "./SubscriptionSection";

const Home = ({ handleLogOut }) => {
  // eslint-disable-next-line
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const location = useLocation();
  const { isLoggedIn } = useContext(UserContext);
  const isHomePage = location.pathname === "/";

  return (
    <div className="d-flex flex-column min-vh-100">
      <Helmet>
        <title>
          Top UK Driving School for Learners & Refreshers | Pass Swiftly
        </title>
        <meta
          name="description"
          content="Discover quality driving lessons with Pass Swiftly, a leading UK driving school offering beginner, intermediate, and refresher courses. Learn to drive confidently and safely!"
        />
      </Helmet>
      <Header handleLogOut={handleLogOut} isLoggedIn={isLoggedIn} />
      <div className="flex-grow-1">
        {isHomePage ? (
          <div style={{ marginTop: "-24px" }}>
            <HeroSection />
            <div
              className="mx-0"
              style={{
                backgroundColor: "#fff6e5",
              }}
            >
              <AfterHeroSection />
            </div>
            <div className={`home ${collapsed ? "collapsed" : ""}`}></div>
            <div>
              <BenifitsSection />
            </div>
            <FindCourseSection />
            <CourseRecommenderSection />
            <Reviews />
            <div className="container d-flex flex-wrap flex-row flex-container">
              <DiscountPromosSection />
              <MoneyBackGuarantee />
            </div>
            <VideosSection />
            <BussinessReviewsSection />
            <FAQSection />
            <SubscriptionSection />
            <BlogsSection />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
