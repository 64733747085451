import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../styles/CourseDetail.css";
import { FaArrowDown, FaArrowUp, FaCalendarAlt } from "react-icons/fa";
import { GiSteeringWheel } from "react-icons/gi";
import { InputSwitch } from "primereact/inputswitch";
import { getAllCourses } from "../services/courses";
import { GlobalContext } from "../contexts/GlobalContext";
import { Helmet } from "react-helmet-async";
import { TbCategory2 } from "react-icons/tb";
const CourseDetail = () => {
  // const [checked, setChecked] = useState(true);
  const [transmission, setTransmission] = useState("manual");
  const [wantTheoryTest, setWantTheoryTest] = useState(false);
  const [wantPracticalTest, setWantPracticalTest] = useState(false);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  // const [allSettings, setAllSettings] = useState([]);
  const { allSettings } = useContext(GlobalContext);
  const [isCourseDetailVisible, setIsCourseDetailVisible] = useState(false);
  const [course, setCourse] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const data = await getAllCourses();
        setCourses(data);
        const course = data.find((item) => item.slug === slug);
        setCourse(course);
        const sortedCourses = data.sort((a, b) => a.hours - b.hours);
        const categoryCourses = sortedCourses.filter(
          (c) => c.category === course.category
        );

        // Find the index of the first course in this category
        const firstCategoryCourseIndex = sortedCourses.findIndex(
          (course) => course.id === categoryCourses[0]?.id
        );

        // Get the course that comes immediately before the first category course
        const previousCourse =
          firstCategoryCourseIndex > 0
            ? sortedCourses[firstCategoryCourseIndex - 1]
            : null;

        const nextCourse =
          firstCategoryCourseIndex + categoryCourses.length <
          sortedCourses.length
            ? sortedCourses[firstCategoryCourseIndex + categoryCourses.length]
            : null;
        // Combine the previous course (if it exists) with the category courses
        const filteredCourses = [
          ...(previousCourse ? [previousCourse] : []),
          ...categoryCourses,
          ...(nextCourse ? [nextCourse] : []),
        ].filter((c) => c.id !== course?.id);

        // const currentCourseIndex = courses.findIndex(
        //   (c) => c.id === course?.id
        // );

        // // Get the previous course
        // const previousCourse =
        //   currentCourseIndex > 0 ? courses[currentCourseIndex - 1] : null;

        // // Get the next 2 courses
        // const nextCourses = courses.slice(
        //   currentCourseIndex + 1,
        //   currentCourseIndex + 3
        // );

        // // Combine previous and next courses, excluding the current course

        // const filteredCourses = [
        //   ...(previousCourse ? [previousCourse] : []),
        //   ...nextCourses,
        // ];
        setFilteredCourses(filteredCourses);
        window.scrollTo(0, 0);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCourses();
  }, [slug]);
  const courseWorkload = course?.hours
    ? "PT" + course.hours + "H" // Concatenate strings instead of using template literals
    : "PT0H";
  const courseCategory = course.category
    ? course.category + " Driving Course"
    : "Driving Course";
  // prettier-ignore
  const courseSchema = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": course?.name || "Pass Swiftly Driving Course",
    "description": course?.description,
    "provider": {
      "@type": "Organization",
      "name": "Pass Swiftly"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "GBP",
      "price": course?.price || 0,
      "category": courseCategory
    },
    "hasCourseInstance": [
      {
        "@type": "CourseInstance",
        "courseMode": "onSite",
        "location": {
          "@type": "Place",
          "name": "Pass Swiftly Driving School",
          "address": "3 Loganswell Drive, Thornliebank, Glasgow, Scotland"
        },
        "courseWorkload": courseWorkload
      }
    ]
  };
  // useEffect(() => {
  //   if (courses.length > 0) {
  //     // const courseId = queryParams.get("courseId");
  //     const courseSlug = queryParams.get("");
  //     console.log(courseSlug);
  //     // console.log(typeof courseSlug);
  //     // console.log(courses);
  //     const filteredCourse = courses.find(
  //       (course) => course.slug === courseSlug
  //       // (course) => course.id === JSON.parse(courseSlug)
  //     );
  //     setCourse(filteredCourse);
  //   }
  // }, [courses, queryParams]);
  // const getCourses = async () => {
  //   await getAllCourses()
  //     .then((data) => {
  //       setCourses(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   // const courseType = queryParams.get("courseType");
  //   // setSelectedCourseType(courseType ? courseType : "Beginner");

  //   // setValue(courseType ? courseType : "Beginner");
  //   getCourses();
  // }, []);
  // useEffect(() => {
  //   const courseId = queryParams.get("courseId");
  //   const filteredCourse = courses.find((course) => {
  //     return course.id === JSON.parse(courseId);
  //   });
  //   console.log(filteredCourse);
  //   setCourse(filteredCourse);
  // }, []);
  const theoryTestPrice = allSettings.find(
    (setting) => setting.name === "theory_test_price"
  )?.value;
  const practicalTestPrice = allSettings.find(
    (setting) => setting.name === "practical_test_price"
  )?.value;

  const renderCourses = () => {
    return (
      filteredCourses
        // .filter((course) => course.category === selectedCourseType)
        // .filter((c) => c.category === course?.category)
        .map((course, index) => (
          <div key={index} className="card-container">
            <Card
              title={() => (
                <>
                  <i className="pi pi-clock" style={{ fontSize: "20px" }} />{" "}
                  {course?.hours} Hours Course <br />
                  <i
                    className="pi pi-calendar"
                    style={{ fontSize: "20px" }}
                  />{" "}
                  {course?.duration}
                </>
              )}
              // subTitle={() => (
              //   <>
              //     <i className="pi pi-pound" style={{ color: "white" }} />
              //     <strong style={{ fontSize: "20px", color: "white" }}>
              //       {course.price}
              //     </strong>
              //   </>
              // )}
              className={`md:w-25rem detail-course-card cc-${course.category.toLowerCase()} `}
              footer={() =>
                course.price && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "max-content" }}
                  >
                    {/* <Link to="/booking"> */}
                    <Link to={`/booking?courseId=${course.id}&`}>
                      <button
                        // className={`btn course-button ${selectedCourseType.toLowerCase()}`}
                        className={`btn  ccb-${course.category.toLowerCase()} text-white hover:text-gray-900`}
                      >
                        Book Now
                      </button>
                    </Link>
                    <Link to={`/course/${course?.slug}`}>
                      <button
                        className={`btn   ccb-${course.category.toLowerCase()}-inverse mx-1 text-black hover:text-white`}
                      >
                        View Details
                      </button>
                    </Link>
                  </div>
                )
              }
            >
              <>
                <p className="d-flex align-items-center fs-5 fw-bold">
                  <TbCategory2 />
                  <span className="mx-1">{course?.category} Course</span>
                </p>
                <div
                  className="cc-card-content mb-5 text-dark text-overflow-ellipsis"
                  style={{ textAlign: "start" }}
                >
                  {" "}
                  <div
                    className="mx-1 text-overflow-ellipsis"
                    dangerouslySetInnerHTML={{ __html: course.details }} // Ensure the content is rendered as HTML
                  />
                  {/* {course.details} */}
                </div>
                {course.from_price && (
                  <div style={{ textAlign: "start" }}>
                    {/* <i className="pi pi-pound" style={{ color: "white" }} /> */}
                    <strong style={{ fontSize: "20px" }}>
                      From Price <i className="pi pi-pound" />
                      {course.from_price}
                      {/* {course.price} */}
                    </strong>
                  </div>
                )}
              </>
            </Card>
          </div>
        ))
    );
  };

  return (
    <div className="justify-content-center align-items-center container-md">
      <Helmet>
        <title>
          {course.name
            ? `${course.name} Driving Course | Pass Swiftly`
            : "Pass Swiftly - Driving Course"}
        </title>
        {/* <title>
          {course
            ? `${course.name} Driving Course | Pass Swiftly`
            : "Pass Swiftly - Driving Courses"}
        </title> */}
        <meta
          name="description"
          content={
            course.name
              ? `Enroll in our ${course.name} driving course tailored for ${course.category}s. Build your driving confidence and skill with our expert instructors.`
              : `Enroll in our driving course tailored for ${course.category}s. Build your driving confidence and skill with our expert instructors.`
          }
        />
        <script type="application/ld+json">
          {JSON.stringify(courseSchema)}
        </script>
      </Helmet>
      <div
        style={{ backgroundColor: "#FFF6E5", marginBottom: "30px" }}
        className="container"
      >
        <div className="page-title-container">
          <h1 className="">{course?.hours} Hours Course</h1>
          <h6 className="">
            <Link to="/" style={{ textDecoration: "none", color: "" }}>
              Home
            </Link>{" "}
            /{" "}
            <Link
              style={{ textDecoration: "none" }}
              to={`/courses/${course.category}`}
              // to={`/courses?courseType=${course?.category}`}
            >
              {course?.category} Courses
            </Link>
          </h6>
        </div>
      </div>
      <div className="d-flex flex-wrap ">
        <div
          className=" card-container "
          style={{ flexGrow: "6", display: "flex", margin: "5px" }}
        >
          <Card
            style={{ border: "1px solid #219EBC" }}
            className="md:w-30rem"
            title={() => (
              <>
                <div className="card-title">
                  <FaCalendarAlt className="title-icon" />
                  <div className="title-section col-6">
                    <div className="title-text">
                      <span>Duration</span>
                      <span className="course-duration">
                        {course?.duration}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card-title">
                  <GiSteeringWheel className="title-icon" />
                  <div className="title-section col-6">
                    <div className="title-text">
                      <span>Experience Level</span>
                      <span>{course?.category}</span>
                    </div>
                  </div>
                </div>

                <hr />
              </>
            )}
          >
            <div className="card-details">
              {" "}
              <p className="card-text">
                {course?.description}{" "}
                {/* <span
                  className="view-details text-primary font-bold cursor-pointer hover:underline ml-2 color-primary "
                  onClick={() =>
                    setIsCourseDetailVisible(!isCourseDetailVisible)
                  }
                >
                  {isCourseDetailVisible ? "Hide Details" : "View Details"}{" "}
                  {isCourseDetailVisible ? <FaArrowUp /> : <FaArrowDown />}{" "}
                </span>
                {isCourseDetailVisible && (
                  <div
                    className="mx-1"
                    dangerouslySetInnerHTML={{ __html: course.details }} // Ensure the content is rendered as HTML
                  />
                )} */}
              </p>
              {/* <Button
                label="View Details"
                className="p-button-text"
                onClick={() => setIsCourseDetailVisible(!isCourseDetailVisible)}
              /> */}
              {/* {isCourseDetailVisible && (
                <div
                  className="mx-1"
                  dangerouslySetInnerHTML={{ __html: course.details }} // Ensure the content is rendered as HTML
                />
              )} */}
            </div>
          </Card>
        </div>
        <div className="d-flex d-md-none flex-wrap align-items-center ">
          <div
            className=" card-container text-center fs-6 w-100"
            style={{ margin: "5px" }}
            // style={{ flexGrow: "6", display: "flex", alignItems: "center" }}
          >
            <div
              className="d-flex flex-wrap align-items-center card-container w-100"
              style={{ margin: "5px" }}
            >
              <p className="text-center fs-4 fw-bold">More Detail</p>
              <div
                className="mx-1"
                dangerouslySetInnerHTML={{ __html: course.details }}
              />
            </div>
          </div>
          <div
            className="side-details col mx-5"
            style={{ flexGrow: "4", backgroundColor: "transparent" }}
          ></div>
        </div>
        <div className="side-details col mx-5" style={{ flexGrow: "4" }}>
          <strong>Starting From</strong>
          <p> £{course?.from_price}</p>
          <Link
            to={`/booking?courseId=${course?.id}&courseType=${course?.category}&transmission=${transmission}&wantTheoryTest=${wantTheoryTest}&wantPracticalTest=${wantPracticalTest}`}
          >
            <button
              className="btn book-now mb-4 hover:text-primary-900"
              style={{ outline: "1px solid black" }}
            >
              Book Now
            </button>
          </Link>
          <div className="Additional-Options">
            <p className="side-title">Transmission Type</p>
            <div className="radio-buttons-container">
              <div>
                <RadioButton
                  inputId="manual"
                  name="transmission"
                  value="manual"
                  //   onChange={handleChange}
                  onChange={(e) => {
                    setTransmission(e.target.value);
                  }}
                  checked={transmission === "manual"}
                  //   checked={input.transmission === "manual"}
                />
                <label htmlFor="manual" className="mx-2">
                  Manual
                </label>
              </div>
              <div style={{ marginTop: "3px" }}>
                <RadioButton
                  inputId="automatic"
                  name="transmission"
                  value="automatic"
                  onChange={(e) => {
                    setTransmission(e.target.value);
                  }}
                  checked={transmission === "automatic"}
                />
                <label htmlFor="automatic" className="mx-2">
                  Automatic
                </label>
              </div>
            </div>{" "}
            <div>
              <p className="side-title">Add Theory Test?</p>
              <InputSwitch
                style={{ marginLeft: "30px" }}
                checked={wantTheoryTest}
                onChange={(e) => setWantTheoryTest(e.value)}
              />
            </div>
            <div style={{ paddingBottom: "50px" }}>
              <p className="side-title">Add Practical Test?</p>
              <InputSwitch
                style={{ marginLeft: "30px" }}
                className="switch"
                checked={wantPracticalTest}
                onChange={(e) => setWantPracticalTest(e.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="d-flex flex-wrap align-items-center card-container w-75">
          <Card
            style={{ border: "1px solid #219EBC" }}
            className="md:w-30rem"
            title={() => (
              <>
                <div className="card-title">
                  <FaCalendarAlt className="title-icon" />
                  <div className="title-section col-6">
                    <div className="title-text">
                      <span>Duration</span>
                      <span className="course-duration">{course.duration}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card-title">
                  <GiSteeringWheel className="title-icon" />
                  <div className="title-section col-6">
                    <div className="title-text">
                      <span>Experience Level</span>
                      <span>{course.category}</span>
                    </div>
                  </div>
                </div>

                <hr />
              </>
            )}
          >
            <div className="card-details">{course.details}</div>
          </Card>
        </div>
        <div className="side-details col w-25">
          <p>£{course.price}</p>
          <button className="btn">Book Now</button>
          <div className="Additional-Options">
            <p className="side-title">Transmission Type</p>
            <div className="radio-buttons-container">
              <div>
                <RadioButton
                  inputId="manual"
                  name="transmission"
                  value="manual"
                  //   onChange={handleChange}
                  //   checked={input.transmission === "manual"}
                />
                <label htmlFor="manual" className="mx-2">
                  Manual
                </label>
              </div>
              <div>
                <RadioButton
                  inputId="automatic"
                  name="transmission"
                  value="automatic"
                  //   onChange={handleChange}
                  //   checked={input.transmission === "automatic"}
                />
                <label htmlFor="automatic" className="mx-2">
                  Automatic
                </label>
              </div>
            </div>{" "}
            <div>
              <p className="side-title">Want to Add Theory Test?</p>
              <InputSwitch
                style={{ marginLeft: "30px" }}
                checked={checked}
                onChange={(e) => setChecked(e.value)}
              />
            </div>
            <div style={{ paddingBottom: "50px" }}>
              <p className="side-title">Want to Add Practical Test?</p>
              <InputSwitch
                style={{ marginLeft: "30px" }}
                className="switch"
                checked={checked}
                onChange={(e) => setChecked(e.value)}
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* <RatingComponent courseId={course.id} /> */}
      <div className="d-none md:flex flex-wrap " style={{ margin: "5px" }}>
        <div
          className=" card-container text-center fs-6"
          style={{ flexGrow: "6", display: "flex", alignItems: "flex-end" }}
        >
          <div
            className="d-flex flex-wrap align-items-center card-container w-100"
            style={{ margin: "5px" }}
          >
            <p className="text-center fs-4 fw-bold">Course Detail</p>
            <div
              className="mx-1"
              dangerouslySetInnerHTML={{ __html: course.details }}
            />
          </div>
        </div>
        <div
          className="side-details col mx-5"
          style={{ flexGrow: "4", backgroundColor: "transparent" }}
        ></div>
      </div>

      <div>
        <p id="other-courses" className="text-center">
          Other Courses
        </p>
        <div className="d-sm-flex justify-content-center row">
          {renderCourses()}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
