import React, { useEffect, useState } from "react";
import { getPayementsByUser } from "../../services/payments";
import { Card } from "primereact/card";
import { DataView } from "primereact/dataview";
import "../../styles/StudentPayments.css";

const StudentPayments = ({ role }) => {
  const [payments, setPayments] = useState([]);

  //   const role=localStorage.getItem("userRole")
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const getPayments = async () => {
      // console.log(role, userId);
      console.log(typeof JSON.parse(role));
      console.log(JSON.parse(role));

      //   const response = role && userId && (await getPayementsByUser());
      const response = await getPayementsByUser(JSON.parse(role), userId);
      // console.log(response);
      setPayments(response);
      //   const data = await response.json();
      //   console.log(data);
      //   setPayments(data);
    };
    getPayments();
  }, []);
  const itemTemplate = (payment) => {
    return (
      <Card
        className="payment-card mx-2"
        title={`Payment on ${new Date(
          payment.payment_date
        ).toLocaleDateString()}`}
      >
        <div className="payment-details">
          <p>
            <strong>Amount:</strong> ${payment.amount}
          </p>
          <p>
            <strong>Payment Method:</strong> {payment.payment_method}
          </p>
          <p>
            <strong>Note:</strong> {payment.payment_note || "No note provided"}
          </p>
          <p>
            <strong>Order No. :</strong> {payment.order_id}
          </p>
        </div>
      </Card>
    );
  };
  return (
    <div className="payments-container container-md">
      <DataView
        value={payments}
        itemTemplate={itemTemplate}
        layout="grid"
        header="Your Payments"
      />
    </div>
  );
};

export default StudentPayments;
