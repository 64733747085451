import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { Column } from "primereact/column";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { MDBPopper } from "mdbreact";
import { Button } from "primereact/button";
import { NewCustomListing } from "../Components/custom/NewCustomListing.jsx";
import { Can } from "../configs/Ability-context.js";
import { formatToCamelCase } from "../Components/custom/Helpers.js";
import { getSubscriptions } from "../services/subscriptions.js";
import { updateCoupon } from "../services/coupons.js";
import { formatDate } from "../Components/Helpers/Helper.js";
const AllSubscriptions = () => {
  const dt = useRef(null);
  const [
    {
      allSubscriptions,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
    },
    setState,
  ] = useState({
    allSubscriptions: [],
    isLoading: false,

    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });

  const settingForm = useRef(null);
  const userRole = localStorage.getItem("userRole");
  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getSubscriptions();
        setState((prevState) => ({
          ...prevState,
          allSubscriptions: data,
          isLoading: false,
        }));
      } catch (error) {
        console.log(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
  }, [refreshData]);

  const handleUpdate = async (id, field, newValue) => {
    try {
      const response = await updateCoupon(id, { [field]: newValue });
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSubscriptions: prevState.allSubscriptions.map((coupon) =>
            coupon.id === id ? { ...coupon, [field]: newValue } : coupon
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  const handleInlineUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, editorText);
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };
  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const handleCancelEdit = () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  const getEditor = (rowData, field) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputText autoFocus value={editorText} onChange={handleInputChange} />
      <Button
        icon="pi pi-check"
        className="p-button-rounded p-button-success p-mr-2"
        style={{ width: "24px" }}
        onClick={() => handleInlineUpdate(rowData, field)}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger"
        style={{ width: "24px" }}
        onClick={handleCancelEdit}
      />
    </div>
  );
  const inlineEditor = (field, rowData) => (
    <Inplace
      closable={false}
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <MDBPopper domElement tag="span" placement="top">
          {rowData[field] ? (
            <span>{rowData[field]}</span>
          ) : (
            <span>
              <FaPlus style={{ cursor: "pointer" }} />
            </span>
          )}
          <span>{rowData[field] ? "Click to edit" : "Click to add"}</span>
        </MDBPopper>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );
  const columns = [
    {
      field: "email",
      header: "Email",
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      //   headerStyle: { width: "290px" },
    },
  ];

  return userRole === `"admin"` ? (
    <Can I="manage" a="all">
      <form
        ref={settingForm}
        className="p-m-0 p-pt-4"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        <NewCustomListing
          allRecords={allSubscriptions}
          heading={"Subscriptions"}
          firstColumn={
            <Column
              field="created_at"
              header="Date"
              body={(rowData) => formatDate(rowData.createdAt)}
              sortable={true}
              filter={true}
              style={{ borderBottom: "1px solid grey" }}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          isLoading={isLoading}
          handleRefresh={() =>
            setState((prevState) => ({
              ...prevState,
              refreshData: !prevState.refreshData,
            }))
          }
          editDataKey={"id"}
          tableRef={dt}
          timeStamps={true}
        />
      </form>
    </Can>
  ) : (
    <>
      <div>you are not allowed to see this page</div>
    </>
  );
};

export default AllSubscriptions;
