import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { getCoupons } from "../../services/coupons";
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";

import { FiCopy } from "react-icons/fi";
const CouponsViewModal = ({ onHide, show }) => {
  const [allCoupons, setAllCoupons] = useState([]);
  const toast = React.useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getCoupons();
        const filteredCoupons = data.filter(
          (coupon) => coupon.is_active && !coupon.individual_use_only
        );
        console.log(filteredCoupons);
        setAllCoupons(filteredCoupons);
        // console.log(allCoupons);
      } catch (error) {
        console.log(error);
      }
    };
    show && fetchData();
  }, [show]);

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    toast.current.show({
      severity: "success",
      summary: "Copied",
      detail: "Coupon code copied to clipboard",
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog onHide={onHide} visible={show} header="Available Promos">
        <div className="p-grid">
          {allCoupons.length >= 1 ? (
            allCoupons.map((coupon) => (
              <div key={coupon.id} className="p-col-12 p-md-6 p-lg-4">
                <Card>
                  <div className="flex flex-column gap-2">
                    <div className="flex align-items-center gap-2">
                      <span className="text-2xl font-bold">Promo Code:</span>
                      <span className="text-2xl">{coupon.code}</span>{" "}
                      <Tooltip
                        target={`#copy-icon-${coupon.id}`}
                        content="Copy Code"
                        position="top"
                      />
                      <FiCopy
                        id={`copy-icon-${coupon.id}`}
                        className="cursor-pointer copy-icon"
                        onClick={() => copyToClipboard(coupon.code)}
                      />
                      {/* <i>
                      <IoCopySharp
                        onClick={() => copyToClipboard(coupon.code)}
                      />
                    </i> */}
                    </div>
                    <div className="flex align-items-center gap-2">
                      <span className="text-2xl font-bold">Discount:</span>
                      <span className="text-2xl">
                        {coupon.type === "percentage"
                          ? `${coupon.value}%`
                          : `£${coupon.value}`}
                      </span>
                    </div>
                    {coupon.expired_at && (
                      <div className="flex align-items-center gap-2">
                        <span className="text-2xl font-bold">Expiry Date:</span>
                        <span className="text-2xl">{coupon.expired_at}</span>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            ))
          ) : (
            <p className="text-center">
              Sorry no promo available at the moment!
            </p>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default CouponsViewModal;

// import { Dialog } from "primereact/dialog";
// import React, { useEffect, useState } from "react";
// import { getCoupons } from "../../services/coupons";

// const CouponsViewModal = ({ onHide, show }) => {
//   const [allCoupons, setAllCoupons] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data } = await getCoupons();
//         const filteredCoupons = data.filter((coupon) => {
//           return coupon.is_active === true;
//         });
//         setAllCoupons(filteredCoupons);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, []);
//   return (
//     <div>
//       <Dialog onHide={onHide} visible={show}>
//         {allCoupons.map((coupon) => (
//           <div className="flex flex-column gap-2">
//             <div className="flex align-items-center justify-content-between">
//               <div className="flex align-items-center gap-2">
//                 <h3 className="text-2xl">Coupon Details</h3>
//               </div>
//             </div>
//             <div className="flex flex-column gap-2">
//               <div className="flex align-items-center gap-2">
//                 <span className="text-2xl">Coupon Code:</span>
//                 <span className="text-2xl">{coupon.code}</span>
//               </div>
//               {coupon.type === "percentage" ? (
//                 <div className="flex align-items-center gap-2">
//                   <span className="text-2xl">Discount:</span>
//                   <span className="text-2xl">{coupon.value}%</span>
//                 </div>
//               ) : (
//                 <div className="flex align-items-center gap-2">
//                   <span className="text-2xl">Discount:</span>
//                   <span className="text-2xl">£{coupon.value}</span>
//                 </div>
//               )}
//               {coupon.expired_at && (
//                 <div className="flex align-items-center gap-2">
//                   <span className="text-2xl">Expiry Date:</span>
//                   <span className="text-2xl">{coupon.expired_at}</span>
//                 </div>
//               )}
//             </div>
//           </div>
//         ))}
//       </Dialog>
//     </div>
//   );
// };

// export default CouponsViewModal;
