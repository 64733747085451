import axios from "axios";

export const createSubscription = async (data) => {
  const response = axios.post("/api/add-subscription", data);
  return (await response).data;
};

export const getSubscriptions = async () => {
  const response = await axios.get("/api/get-subscriptions");
  return response.data;
};
